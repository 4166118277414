<template>
  <div class="p-3">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
        autocomplete="off"
      >
        <b-row>
          <b-col cols="12">
            <validation-provider
              name="Başlık"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label="Başlık">
                <b-form-input
                  size="lg"
                  class="rounded-0 mb-3"
                  ref="kullanici_adi"
                  v-model="form.baslik"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Adres">
              <b-form-textarea
                id="adres"
                v-model="form.adres"
                class="rounded-0 mb-3"
                rows="2"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Sabit Telefon">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="sifre"
                v-model="form.sabit"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Fax">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="sifre"
                v-model="form.fax"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Cep Telefon 1">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="sifre"
                v-model="form.gsm_1"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Cep Telefon 2">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="sifre"
                v-model="form.gsm_2"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider name="E Mail">
              <b-form-group label="E Mail">
                <b-form-input
                  size="lg"
                  class="rounded-0 mb-3"
                  ref="e_mail"
                  v-model="form.e_mail"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider name="Yol Tarifi">
              <b-form-group label="Yol Tarifi">
                <b-form-input
                  size="lg"
                  class="rounded-0 mb-3"
                  ref="e_mail"
                  v-model="form.yol_tarifi"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="Çalışma Saati Baslama - Bitiş">
              <b-form-input
                size="lg"
                class="rounded-0 mb-3"
                v-model="form.calisma_saat"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              label="Cumartesi Saati Baslama - Bitiş"
              description="Boş Kalırsa Kapalı"
            >
              <b-form-input
                size="lg"
                class="rounded-0 mb-3"
                v-model="form.cumartesi_saat"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              label="Pazar Saati Baslama - Bitiş"
              description="Boş Kalırsa Kapalı"
            >
              <b-form-input
                size="lg"
                class="rounded-0 mb-3"
                v-model="form.pazar_saat"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex">
              <b-button
                squared
                block
                type="submit"
                size="lg"
                variant="primary"
                class="mr-2"
              >
                <i class="fad fa-save pr-2"></i>
                {{ form.k_no == null ? "EKLE" : "GÜNCELLE" }}
              </b-button>
              <b-button squared size="lg" variant="danger">
                <i class="fad fa-trash"></i>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
export default defineComponent({
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.form = ref({
      k_no: null,
      baslik: null,
      adres: null,
      fax: null,
      sabit: null,
      gsm_1: null,
      gsm_2: null,
      e_mail: null,
      yol_tarifi: null,
      calisma_saat: "08:00 - 17:00",
      cumartesi_saat: "08:00 - 15:00",
      pazar_saat: null,
    });

    const handlerConfig = async () => {
      context.emit("show", true);
      await store.dispatch("iletisimGetir").then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      context.emit("show", false);
    };

    handlerConfig();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit("show", true);
      store
        .dispatch("iletisimEkle", expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(
              expo.form.value.k_no == null
                ? "Ekleme Başarılı."
                : "Güncelleme Başarılı",
              { position: "bottom-left" }
            );
            context.emit("show", false);
          }
        })
        .catch((err) => {
          context.emit("show", false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0
                ? Object.keys(err.data.dup)[0] + " " + err.data.message
                : err.data.message,
              { position: "bottom-left" }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
